<template>
	<footer class="site-footer">
		<div class="container">
			<div class="footer-row">
				<div class="footer-logo">
					<div class="logo"></div>
				</div>
				<div class="footer-btn">
					<button class="btn btn-outline-light" onclick="window.open('mailto:btcth@btcth.finance')">Contact</button>
					<button class="btn btn-outline-light" @click="goto('/faq')">FAQ</button>
				</div>
				<div class="footer-sns">
					<a :href="prjConst('twitter')" target="_blank"><img src="img/logo-sns-twitter-dark.svg"></a>
					<a :href="prjConst('telegram_chat')" target="_blank"><img src="img/logo-sns-telegram-dark.svg"></a>
					<a :href="prjConst('medium')" target="_blank"><img src="img/logo-sns-medium-dark.svg"></a>
					<a :href="prjConst('github')" target="_blank"><img src="img/logo-sns-github-dark.svg"></a>
				</div>
			</div>
		</div>
	</footer>	
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import PrjConstants from '@/config/constants.js'
import PrjConst from '@/mixins/prjconst.js'

export default {
  name: 'MainFooter',
  mixins: [ PrjConst ],
  props: {
  },
  computed: {
  },
  methods: {
	  goto(r) {
		  this.$router.push(r);
		  window.scrollTo({top: 0, behavior: 'smooth'});
	  }
  },
}
</script>

